import React from 'react';
import { Link } from 'gatsby';
import { ReactComponent as FourOhFourSvg } from '../assets/images/404.svg';
import './404.scss';

const FourOhFour = () => (
  <>
    <section className="four-oh-four-page">
      <div className="svg-wrapper">
        <FourOhFourSvg />
      </div>
      <span className="return-to-home">
        Back to
        <Link
          to="/"
          aria-label="Return to the home page"
        >
          Home
        </Link>
      </span>
    </section>
  </>
);

export default FourOhFour;
